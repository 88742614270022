

import React, {  useRef, useState } from 'react'
import { useMediaPredicate } from 'react-media-hook';
import EmailIcon from '../icons/EmailIcon';
import MapPinIcon from '../icons/MapPinIcon';
import PhoneIcon from '../icons/PhoneIcon';
import { collection, addDoc } from "firebase/firestore"; 
import { CaretDown, Clock, X } from '@phosphor-icons/react';
import { validEmail, validPhone } from '../Regex';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarBlank } from 'phosphor-react';
import Select from 'react-select';

export default function ContactBlock({db, brochureDownload}) {
  const [emailHover, setEmailHover]=useState('none')
  const [phoneHover, setPhoneHover]=useState('none')
  const [addressHover, setAddressHover]=useState('none')
  const [sendingBtn, setSendingBtn]=useState('send message')
  const [messageSuccess, setMessageSuccess]=useState(false);
  const [dateAlert, setDateAlert]=useState(false);
  const [phoneAlert, setPhoneAlert]=useState(false);
  const [emailAlert, setEmailAlert]=useState(false);
  const [startDate, setStartDate] = useState();
  const reactSelectRef=useRef(null);
  const current_date=new Date();
  const max_width = useMediaPredicate("(max-width: 760px)");
  const max_width2 = useMediaPredicate("(max-width: 893px)");
   const reactSelectReferred=[{value:'Vaccine Clinic',label:'Vaccine Clinic'},{value:'Family or Friends',label:'Family or Friends'},{value:'Referral Company',label:'Referral Company'},
            {value:'Magazine',label:'Magazine'},{value:'Newspaper',label:'Newspaper'},{value:'Billboard',label:'Billboard'},{value:'Facebook/Instagram',label:'Facebook/Instagram'},{value:'Other ad',label:'Other ad'},{value:'Online',label:'Online'},{value:'Drive by',label:'Drive by'},{value:'Other',label:'Other'}];
  const reactSelectTimes=[{value:'8:00am',label:'8:00am'},{value:'8:30am',label:'8:30am'},{value:'9:00am',label:'9:00am'},{value:'9:30am',label:'9:30am'},{value:'10:00am',label:'10:00am'},{value:'10:30am',label:'10:30am'},{value:'11:00am',label:'11:00am'},{value:'11:30am',label:'11:30am'},{value:'12:00pm',label:'12:00pm'},{value:'12:30pm',label:'12:30pm'},
  {value:'1:00pm',label:'1:00pm'},{value:'1:30pm',label:'1:30pm'},{value:'2:00pm',label:'2:00pm'},{value:'2:30pm',label:'2:30pm'},{value:'3:00pm',label:'3:00pm'},{value:'3:30pm',label:'3:30pm'},{value:'4:00pm',label:'4:00pm'},{value:'4:30pm',label:''},{value:'5:00pm',label:'5:00pm'},{value:'5:30pm',label:'5:30pm'},{value:'6:00pm',label:'6:00pm'},{value:'6:30pm',label:'6:30pm'},
  {value:'7:00pm',label:'7:00pm'},{value:'7:30pm',label:'7:30pm'},{value:'8:00pm',label:'8:00pm'}]
  const reactSelectMoveIn=[{value:'0-30 Days',label:'0-30 Days'},{value:'30-60 Days',label:'30-60 Days'},{value:'60-90 Days',label:'60-90 Days'},
            {value:'90+ Days',label:'90+ Days'}];
  const handleEventPush=()=>{
    window.dataLayer.push({
      event_name:'contact_form_submitted',
      event:'contact_form_submitted'
    })
  }

  const submitContactForm=async(e)=>{
    const sending='sending'
    setSendingBtn(sending)
    
      e.preventDefault();
      if (e.target.digitalBrochure.checked){
        brochureDownload()
      }
      const availableTimeList=[]
      for (let i = 0; i < e.target.availableTime.length; i++){
          availableTimeList.push(e.target.availableTime[i].value)
      }
     
      try {
      const docRef = await addDoc(collection(db, "contactForms"), {
        full_name: e.target.fullName.value,
        email: e.target.email.value,
        phone_number: e.target.phoneNumber.value,
        contact_date:e.target.contactDate.value,
        times_to_contact:availableTimeList,
        date_submitted:current_date,
        move_in_preference:e.target.moveIn.value,
        notes: e.target.notes.value,
        to:['doemelsydney@gmail.com', 'referralscorso@corsoatlanta.com','referralscorso@villageparkseniorliving.com'],
        message:{
          subject:'New submission from "Schedule a Call" form',
          html:`Full Name: ${e.target.fullName.value} <br> Email: ${e.target.email.value}<br> Phone: ${e.target.phoneNumber.value}<br>
          Preferred Contact Date: ${e.target.contactDate.value}<br> Preferred Contact Times: ${availableTimeList}
          <br> Referred by: ${e.target.referredBy2.value}
          <br> Preferred Move-In Timeline: ${e.target.moveIn.value}
          <br> Message: ${e.target.notes.value}<br>
           Date of Submission: ${current_date}<br> Page: Corso Landing Page`
        }
      });
      
      handleEventPush()
      const sent='sent'
      setSendingBtn(sent)
      setMessageSuccess(true)
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  }
  const handleExit=()=>{
    setMessageSuccess(false)
    const sendmessage='send message'
    setSendingBtn(sendmessage)
  }
  const handleDateAlert=((date)=>{
    setStartDate(date)
    current_date.toLocaleDateString()
    date.toLocaleDateString()
     if (current_date <= date){
      setDateAlert(false)
    }else{
      setDateAlert(true)
    }
  })
  const validateEmail = (e) => {
    if (!validEmail.test(e.target.value)) {
       setEmailAlert(true);
    }else{
      setEmailAlert(false)
    }
  }
    const validatePhone = (e) =>{
    if (!validPhone.test(e.target.value)) {
       setPhoneAlert(true);
    }else{
      setPhoneAlert(false)
    }
 };

  const openReactSelect=()=>{
    reactSelectRef.current.focus();
  };
 return (
   <div id='contactForm' className='contact-column'>
     <div className='contact-div'>
      <div className='contact-info'>
        <div className='contact-blurb div-copy' >
          <p className='contact-us'>Contact Us</p>
          <p className='schedule-a-call'>
            Schedule A Call
          </p>
        </div>
          <p className='contact-description'>
            please fill out the form and someone will
            contact you shortly
          </p>
      </div>
       {max_width?<>
       </>:<>
       <div className='contact-specifics-2'>
            <a className='email-button2' href='mailto:referralscorso@corsoatlanta.com' onMouseOver={()=>setEmailHover('underline')} onMouseOut={()=>setEmailHover('none')} data-testid='contact-block-email-btn'>
              <div className='email-icon'>
              <EmailIcon style={{fill:'black'}}/>
              </div>
              <p className='email-text' style={{textDecoration:emailHover}}>
                referralscorso&#64;corsoatlanta.com
              </p>
            </a>
            <a className='phone-button2 contact-phone-btn' href='tel:+4048919190' onMouseOver={()=>setPhoneHover('underline')} onMouseOut={()=>setPhoneHover('none')} data-testid='contact-block-phone-btn'>
              <div className='phone-icon'>
              <PhoneIcon style={{fill:'black'}}/>
              </div>
              <p className='email-text'style={max_width?{textDecoration:phoneHover}:{textDecoration:'none'}} >
               (404) 891-9190
              </p>
            </a>
            <a className='address-button2 contact-address-btn' href="http://maps.google.com/?q=3200 Howell Mill Rd NW Atlanta, GA 30327"  rel="noreferrer" target="_blank" onMouseOver={()=>setAddressHover('underline')} onMouseOut={()=>setAddressHover('none')} data-testid='contact-block-address-btn'>
              <div className='address-icon'>
                <MapPinIcon style={{fill:'black'}}/>
              </div>
              <p className='email-text' style={{textDecoration:addressHover}}>
                3200 Howell Mill Rd NW Atlanta, GA 30327
              </p>
            </a>
          </div>
       </>}
       
     </div>
    <div id='contactForm' className='contact-form'>
       <form action='' onSubmit={(e)=>submitContactForm(e)}>
        {messageSuccess?<>
        <div className='success-msg-header-div'>
          <h1 className='success-msg-header'>
            Thank you!
          </h1>
          <X onClick={handleExit} color='rgba(199, 193, 186, 1)' size={32} />
        </div>
          <p className='success-msg-text'>We appreciate you contacting Corso Atlanta. One of our team members will get in touch with you soon. </p>
          <p className='success-msg-text'>In the meantime, feel free to look at our gallery and the amenities our residences provides.</p>
          <p className='success-msg-text'>Have a great day!</p>
          <button  className='submit-contact-btn'>
               <p className='submit-contact-btn-txt'>
                   Sent
               </p>
               </button>
        </>:
        <>
        <div className='form-input'>
               <label className='' htmlFor='fullName'>Full Name</label>
               <input className='' required type='text' id='fullName' name='fullName' placeholder='Full Name'/>
           </div>
           <div className='phone-email-div-contact'>
              <div className='form-input contact-phone'>
                    <div className='label-div'>
                       {/* email alert is here for styling purposes to create space  */}
                       {!max_width2&&<>{emailAlert?<><p style={{color:'rgba(238, 236, 230, 1)', fontSize:'14px'}}>Enter a valid phone number</p></>:<></>}</>}
                      <label className='' htmlFor='phoneNumber'>Phone Number</label>
                      {phoneAlert?<><p style={{color:'red', fontSize:'14px'}}>Enter a valid phone number</p></>:<></>}
                    </div>
                    <input className='' onChange={(e)=>{validatePhone(e)}}  type='tel' id='phoneNumber' name='phoneNumber' defaultValue='' placeholder='Phone Number'/>
                </div>
                <div className='form-input contact-email'>
                    <div className='label-div'>
                      {/* phone alert is here for styling purposes to create space  */}
                      {!max_width2&&<>
                        {phoneAlert?<><p required style={{color:'rgba(238, 236, 230, 1)', fontSize:'14px'}}>Enter a valid email</p></>:<></>}
                      </>}
                      <label className='' htmlFor='email'>Email</label>
                      {emailAlert?<><p required style={{color:'red', fontSize:'14px'}}>Enter a valid email</p></>:<></>}
                    </div>
                    <input className='' onChange={(e)=>{validateEmail(e)}} type='text' id='email' name='email' placeholder='Email'/>
                </div>
           </div>
       
           <div className='date-time-div-contact'>
                <div className='date-picker-div'>
                    <label className='' htmlFor='contactDate'>Preferred Date to Contact</label>
                    {dateAlert?<><p style={{color:'red', fontSize:'14px', marginBottom:'10px'}}>Please enter a future date</p></>:<></>}
                    <CalendarBlank className='contact-calendar-icon' color='rgba(199, 193, 186, 1)' size={20} />
                    <DatePicker className='quiz-date-picker' portalId='root-portal' required selected={startDate}  onChange={(date) => handleDateAlert(date)} id='contactDate' name='contactDate' placeholderText='Select Date'/>
                    <CaretDown className='contact-date-caret' size={20} />
                </div>
                <div className='time-picker-div' onClick={openReactSelect}>
                   {/* date alert is here for styling purposes to create space  */}
                   {!max_width2&&<>
                    {dateAlert?<><p style={{color:'rgba(238, 236, 230, 1)', fontSize:'14px', marginBottom:'10px'}}>Please enter a future date</p></>:<></>}
                    </>}
                    <label className='' htmlFor='availableTime'>Available Hours To Contact</label>
                    
                    <Select openMenuOnClick openMenuOnFocus ref={reactSelectRef} className="quiz-time-picker"  placeholder='Select Hours' classNamePrefix='react-select2' name='availableTime' id='availableTime' options={reactSelectTimes} isMulti 
                        styles={{control: (baseStyles, state) => ({...baseStyles,padding:'11px',borderRadius: '8px',})}}/>
                    <Clock className='contact-clock-icon' color='rgba(199, 193, 186, 1)' size={20}  />
                    <CaretDown className='contact-time-caret' size={20} onClick={openReactSelect}/>
              </div>

            </div>
            
              <div className='referred-by-div2'>
                  <label className='' htmlFor='referredBy2'>How did you hear about us?</label>
                  <Select openMenuOnClick openMenuOnFocus  required className="referred-by2"  placeholder='Select' classNamePrefix='react-select2-referred' name='referredBy2' id='referredBy2' options={reactSelectReferred} 
                      styles={{control: (baseStyles, state) => ({...baseStyles,padding:'10px',borderRadius: '8px',})}}/>
                  <CaretDown className='referred-caret-icon2' size={20}/>
              </div>
               <div className='referred-by-div2'>
                  <label className='' htmlFor='referredBy2'>When are you looking to move in?</label>
                  <Select openMenuOnClick openMenuOnFocus  required className="referred-by2"  placeholder='Select' classNamePrefix='react-select2-referred' name='moveIn' id='moveIn' options={reactSelectMoveIn} 
                      styles={{control: (baseStyles, state) => ({...baseStyles,padding:'10px',borderRadius: '8px',})}}/>
                  <CaretDown className='referred-caret-icon2' size={20}/>
              </div>
           <div className='form-input'>
               <label className='' htmlFor='notes'>Tell Us About Yourself</label>
               <textarea style={{height:'150px'}} className='' id='notes' name='notes' placeholder='Leave any additional notes here'/>
           </div>
           
           <div className='brochure-checkbox' data-testid='contact-receive-brochure-btn'>
            <input type='checkbox' id='digitalBrochure' name='digitalBrochure'  className='digital-brochure' />
            <label htmlFor='digitalBrochure'>I want to receive a digital brochure.</label>
           </div>
           <button type='submit'  className='submit-contact-btn' data-testid='submit-contact-form-btn'>
               <p className='submit-contact-btn-txt'>
                   {sendingBtn}
               </p>
               </button>
        </>}
           
          
       </form>
    </div>
   
   </div>
 )
}
